import React from "react";
import HomeTrending from "../components/HomeTrending";
import Department from "../components/Department";
import { useEffect } from "react";
import Slider from "../components/Slider";
import img from "../img/sv3.png";
import TrendingVideos from "../components/Trendings/TrendingVideos";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TrendingCom from "../components/Trendings/TrndingComp";

import { useGlobalContext } from "../Context/GlobalContextOne";
import { Helmet } from "react-helmet-async";
const Home = () => {
  const navigate = useNavigate();
  const { setActivePage, activePage, setSearchKeyWords } = useGlobalContext();
  useEffect(() => {
    setActivePage("home");
  }, [activePage]);
  useEffect(() => {
    fetch("https://railwaymcq.com/railwaymcq/RailPariksha/Visitors_count.php")
      .then((response) => response.text())
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handleClick = () => {
    navigate("/Feedback");
  };
  return (
    <div className="container text-center mt-12" style={{ minHeight: "90vh" }}>
      <Helmet>
        <title>
          MCQ Town | Free Multiple Choice Questions for Exam Preparation
        </title>
        <meta
          name="description"
          content="MCQ Town offers a wide selection of free multiple-choice questions across various subjects. Prepare for exams with quizzes in Math, GK, Science, Engineering, Law, and more. Perfect for students and competitive exam aspirants."
        />
        <link rel="canonical" href="https://mcqtown.com/" />
        <meta
          name="title"
          content="MCQ Town | Free MCQs for Competitive Exams and Learning"
        />
        <meta
          name="keywords"
          content="MCQ, free multiple choice questions, online quizzes, exam preparation, competitive exams, school quizzes, general knowledge, science MCQ, math MCQ, engineering MCQ, law MCQ"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph tags for social media */}
        <meta
          property="og:title"
          content="MCQ Town | Free MCQs for Exam Prep"
        />
        <meta
          property="og:description"
          content="Explore a wide range of free multiple-choice questions on MCQ Town. Prepare for exams and quizzes with our MCQs in Math, GK, Science, and more."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mcqtown.com/" />
        <meta
          property="og:image"
          content="https://mcqtown.com/images/home-thumbnail.jpg"
        />

        {/* Twitter card for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="MCQ Town | Free MCQs for Exam Prep"
        />
        <meta
          name="twitter:description"
          content="MCQ Town offers a broad collection of free MCQs across subjects like Math, GK, and Science. Ideal for students and exam prep."
        />
        <meta
          name="twitter:image"
          content="https://mcqtown.com/images/home-thumbnail.jpg"
        />
      </Helmet>

      {/*  image for home */}
      <div>
        <Slider />
        <br />
        <p className="ms-5 me-5">
          <h6>Your Ultimate MCQ Destination!</h6>
          Looking to sharpen your knowledge and ace your exams? MCQ Town is the
          one-stop solution for all your multiple-choice question needs. Explore
          an extensive collection of MCQs covering a wide range of topics,
          including:
          <ul className="text-start ms-3">
            <li>
              <h6> Stay updated and expand your horizons.</h6>
            </li>
            <li>
              <h6>School Subjects:</h6> Perfect for students from primary to
              higher secondary levels.
            </li>
            <li>
              <h6>College Topics:</h6> Dive into complex concepts with
              easy-to-practice questions.
            </li>
            <li>
              <h6>Competitive Exams:</h6> Prepare for exams like UPSC, SSC, Bank
              PO, and more with targeted MCQs.
            </li>
            And many more
          </ul>
        </p>
        {/* <div className="col-12 col-md-6 ">
            <img
              src={home}
              alt="study"
              style={{
                height: "100%",
                width: "100%",
                minHeight: "120px",
                minWidth: "150px",
                objectFit: "contain",
              }}
            />
          </div>

          <div
            className="col-12 col-md-6 d-flex justify-content-center align-items-center text-center"
            style={{ minHeight: "150px" }}
          >
            <div>
              <h4>Rail Pariksha</h4>

              <h3>Excellence Through Practice</h3>
              <p>
                Learn{"  "} <FaPlay style={{ color: "lightGreen" }} /> Practice
                {"  "}
                <FaPlay style={{ color: "lightGreen" }} /> Improve{"  "}
                <FaPlay style={{ color: "lightGreen" }} /> Succeed
              </p>
            </div>
          </div> */}
      </div>
      {/* image ends here */}

      {/* <div className="row mt-3 p-2 m-1 papaDiv">


        <div className="position-relative col-12 ">
          <div className="  d-flex align-items-center  justify-content-center ">
            <div style={{ fontSize: "12px" }}>
              <h6>Disclamer</h6>
              This website, Rail-Pariksha, is dedicated to learning and teaching
              purposes. All content is intended solely for educational purposes.
              If you believe that any content on this website belongs to you and
              should not be displayed, please raise an objection by contacting
              us directly. We are committed to addressing any concerns promptly
              and appropriately. Thank you for your understanding and
              cooperation.
            </div>
            <br />{" "}
          </div>
          <div className="text-end">
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={handleClick}
            >
              {" "}
              Raise an objection
            </button>
          </div>
        </div>
 
      </div> */}

      <div className="row mt-3">
        <div className="col-12">
          <TrendingCom from={"home"} />
          <h6> Explore What’s Trending at MCQ Town</h6>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <TrendingVideos from={"home"} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <Department />
        </div>
      </div>
      <p>
        <h6>
          Our platform is designed to help learners of all ages, from students
          to professionals, test their skills and learn effectively. Each MCQ is
          crafted with accuracy and relevance to ensure you’re always a step
          ahead.
        </h6>
        <h6 className="text-start ms-5 me-5" style={{ color: "red" }}>
          Why Choose MCQ Town?
        </h6>
        <ul className="text-start ms-5 me-5">
          <li>
            <h6>User-Friendly: Simple and intuitive interface.</h6>
          </li>
          <li>
            <h6>Regular Updates: Fresh content added frequently.</h6>
          </li>
          <li>
            <h6>
              Diverse Categories: Covering education, general aptitude, current
              affairs, and more.
            </h6>
          </li>
          <li>
            <h6>
              Start your journey today with MCQ Town and master every topic
              effortlessly!
            </h6>
          </li>
        </ul>
      </p>
    </div>
  );
};
export default Home;
